import { forkJoin as observableForkJoin } from 'rxjs';
import { Injectable } from '@angular/core';
import { Entity } from '@proman/services/entity.service';
import { clone } from '@proman/utils';
import { ACL } from '@proman/services/acl.service';
import { FilterService } from '@proman/services/filter.service';
import { ProductionsService } from '../../production/services/productions.service';
import {
    MaterialCategory,
    OrderParameter, Employee, ProductionOperation,
    ResourceBooking,
} from '@proman/interfaces/entity-interfaces';
import { OrderEntityInterface } from '@proman/resources/order';
import { InputDialogComponent } from '@frontend/shared/components/input-dialog.component';
import { Dialog } from '@frontend/shared/services/dialog.service';

@Injectable()
export class EventsService {
    orderEntity: OrderEntityInterface;
    eventEntity: any;

    constructor(
        private ACL: ACL,
        private Entity: Entity,
        private Filter: FilterService,
        private Dialog: Dialog,
        private Productions: ProductionsService,
    ) {
        this.eventEntity = Entity.get('event');
        this.orderEntity = Entity.get('order') as OrderEntityInterface;
    }

    getWorkgroupRestriction = (event: ResourceBooking) => this.ACL.check(undefined, event.workplace && event.workplace.workgroup ? event.workplace.workgroup.id : null);

    loadParameters = async (event: any): Promise<any> => {
        const production = event?.production;
        let promise;
        let result: any;

        if (production) {
            promise = observableForkJoin([
                    this.orderEntity.QB.joinParameters()
                        .get({ 'productions.id': production.id })
                        .then(null, (): any => null),
                    this.Productions.loadTechnology(production),
                ])
                .toPromise();
        } else if (event.order) {
            promise = observableForkJoin([this.orderEntity.QB.joinParameters().get({ id: event.order.id })]).toPromise();
        }

        if (promise && promise.then) {
            await promise.then((values: any[]) => {
                const orderParameters: OrderParameter[] = values[0] && values[0].parameters || [];
                const parameters: any = {};

                parameters.orderParameters = orderParameters
                    .filter((item: any) => this.filterParameters(item, event));

                if (production) {
                    parameters.productionGroups = this.filterGroups(production.groups, event);

                    if (production.ungroupedParameters) {
                        parameters.productionParameters = production.ungroupedParameters.filter((item: any) => this.filterParameters(item, event));

                    }

                    if (production.filteredMaterialCategories) {
                        parameters.productionMaterialCategories = production.filteredMaterialCategories.filter((item: any) => this.filterMaterialCategories(item, event));

                    }
                }

                result = parameters;
            });

        }

        return new Promise((resolve) => resolve(result));
    };

    isPersonalEvent = (event: any, employeeId: number) => {
        let isPersonal = false;
        let employees = event.employees || [];

        for (let iter = 0; iter < employees.length; iter++) {
            let employee = employees[iter].employee;

            if (employee && employeeId === employee.id) {
                isPersonal = true;

                break;
            }
        }

        return isPersonal;
    };

    filterMaterialCategories = (materialCategory: MaterialCategory, event: ProductionOperation) => {
        let isFiltered = false;
        let categories = event.articleOperation.operation.visibleMaterialCategories;
        let category;
        let iter;

        for (iter = 0; iter < categories.length; iter++) {
            category = categories[iter];

            if (category.id === materialCategory.id) {
                isFiltered = true;

                break;
            }
        }

        return isFiltered;
    };

    filterParameters = (parameterOverride: any, event: any) => {
        let isFiltered = false;
        let parameters = event.articleOperation.operation.parameters;

        for (let iter = 0; iter < parameters.length; iter++) {
            let parameter = parameters[iter];

            if (parameter.id === parameterOverride.parameter.id) {
                isFiltered = true;

                break;
            }
        }

        return isFiltered;
    };

    filterGroups = (groups: any, event: any) => {
        let filtered = {};
        let parameters;
        let parameterChildren;

        const filter = (item: any) => this.filterParameters(item, event);

        for (let id in groups) {
            let group = clone(groups[id]);
            let children = group.metaParameters[0].children;

            children = children.filter(filter);

            if (children.length === 0) {
                continue;
            }

            filtered[id] = group;

            parameters = group.parameters;

            for (let iter = 0; iter < parameters.length; iter++) {
                parameterChildren = parameters[iter].children;

                parameterChildren = parameterChildren.filter(filter);
            }
        }

        return filtered;
    };

    isSupervisedEvent = (event: any, employeeId: number) => {
        return event.supervisor && event.supervisor.id === employeeId;
    };

    showOptions = () => {
        return this.ACL.check('event.edit') || this.ACL.check('event.update_status');
    };

    canUpdateWorkplace = (event: any) => {
        return this.ACL.check('event.edit') && this.getWorkgroupRestriction(event);
    };

    canUpdateEmployees = (event: any) => {
        return this.ACL.check('event.update_status') && this.getWorkgroupRestriction(event);
    };

    canUpdateTime = (event: any) => {
        return this.ACL.check('event.update_status') && this.getWorkgroupRestriction(event);
    };

    canUpdateSupervisor = (event: any) => {
        return this.ACL.check('event.update_status') && this.getWorkgroupRestriction(event);
    };

    canUpdateParameters = (event: any, employeeId: number) => {
        return (this.isPersonalEvent(event, employeeId) || this.ACL.check('event.edit') && this.getWorkgroupRestriction(event)) && event.eventStatus === this.eventEntity.STARTED;
    };

    showItemQuantities = (event: any) => {
        return event.articleOperation.productionStoreAccess && (event.eventStatus === this.eventEntity.STARTED || event.eventStatus === this.eventEntity.FINISHED);
    };

    canUpdateItemQuantities = (event: any, employeeId: number) => {
        return (this.isPersonalEvent(event, employeeId) || this.ACL.check('event.edit')) && event.articleOperation.operation.productionStoreAccess && (event.eventStatus === this.eventEntity.STARTED || event.eventStatus === this.eventEntity.FINISHED);
    };

    canUpdateEvent = (event: any) => {
        return this.ACL.check('event.edit') && this.getWorkgroupRestriction(event);
    };

    canStart = (event: any, employeeId: number) => {
        return (this.isPersonalEvent(event, employeeId) || this.isSupervisedEvent(event, employeeId) || this.ACL.check('event.update_status') && this.getWorkgroupRestriction(event)) &&
            (event.eventStatus === this.eventEntity.UNSTARTED || event.eventStatus === this.eventEntity.CANCELED || event.eventStatus === this.eventEntity.WAITING_FOR_PREVIOUS);
    };

    canEnd = (event: any, employeeId: number) => {
        return (this.isPersonalEvent(event, employeeId) || this.isSupervisedEvent(event, employeeId) || this.ACL.check('event.update_status') && this.getWorkgroupRestriction(event)) && event.eventStatus === this.eventEntity.STARTED;
    };

    canCancel = (event: any, employeeId: number) => {
        return (this.isPersonalEvent(event, employeeId) || this.isSupervisedEvent(event, employeeId) || this.ACL.check('event.update_status') && this.getWorkgroupRestriction(event)) && event.eventStatus === this.eventEntity.STARTED;
    };

    canComment = (event: any, employeeId: number) => {
        return this.ACL.check('event.edit') || this.isPersonalEvent(event, employeeId) || this.isSupervisedEvent(event, employeeId);
    };

    showComments = (event: any) => {
        return event.eventStatus === this.eventEntity.STARTED || event.eventStatus === this.eventEntity.FINISHED || event.eventStatus === this.eventEntity.CANCELED || event.eventStatus === this.eventEntity.UNCONFIRMED;
    };

    canUpdateFiles = (event: any, employeeId: number) => {
        return (this.isPersonalEvent(event, employeeId) && event.articleOperation.fileAccess && this.getWorkgroupRestriction(event)) && event.eventStatus === this.eventEntity.STARTED;
    };

    canConfirm = (event: any, employeeId: number) => {
        return event.eventStatus === this.eventEntity.UNCONFIRMED && (this.isSupervisedEvent(event, employeeId) || this.ACL.check('event.edit') && this.getWorkgroupRestriction(event));
    };

    setBookingsNames = (bookings: ResourceBooking[]) => {
        const getName = (booking: ResourceBooking) => {
            booking.bookingName = this.Filter.smartDateRange(booking.plannedStart, booking.plannedEnd);

            if (booking.realStart) booking.bookingName += ` (${this.Filter.smartDateRange(booking.realStart, booking.realEnd)})`;

        };

        for (let booking of bookings) {
            getName(booking);
        }

    };

    createDefectAct(booking: ResourceBooking): Promise<unknown> {
        let employeeOptions: Employee[] = [];

        return this.Entity.get('resource_booking').get({ id: booking.id, join: ['employee', 'confirmedBy', 'event'] }).then((response) => {
            if (response.confirmedBy) {
                employeeOptions.push(response.confirmedBy);
            }
            if (response.employee) {
                employeeOptions.push(response.employee);
            }
        }).then(() => {
            return this.Dialog
                .open(InputDialogComponent,
                    {
                        header: 'select_template',
                        mainField: {
                            key: 'template',
                            name: 'template',
                            type: 'autocomplete',
                            config: { label: 'template', entity: 'template', entityParams: { context: 'defect_act' }, autoSelectSingleOption: true },
                        },
                        parameters: [
                            {
                                name: 'type',
                                key: 'type',
                                type: 'select',
                                value: { id: 'employee', name: this.Filter.translate('employee') },
                                config: {
                                    options: ['employee', 'material', 'technology', 'workplaces', 'process', 'information'].map((item: any) => ({ id: item, name: this.Filter.translate(item) })),
                                    disableSearch: true,
                                    key: 'id'
                                }
                            },
                            {
                                name: 'comment',
                                key: 'comment',
                                type: 'text',
                                config: {},
                            },
                            {
                                name: 'employee',
                                key: 'employee',
                                type: 'select',
                                value: booking.employee,
                                config: { options: employeeOptions, disableSearch: true },
                            }
                        ]
                    },
                    { disableAutoFocus: true })
                .then((result: any) => this.Entity.get('template').defectAct({...result, resourceBooking: booking.id}));
        })
    }

}
